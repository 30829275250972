import { deleteCookie, H3Event, parseCookies, setCookie } from 'h3';
import { ref } from 'vue';

/**
 * useConnectID:
 */
export default function useConnectID() {
  const loading = ref(false);
  const path = '';

  function setCookies(event: H3Event, cookies: { [key: string]: string }): void {
    setCookie(event, 'state', cookies.state, { path, sameSite: 'none', secure: true });
    setCookie(event, 'nonce', cookies.nonce, { path, sameSite: 'none', secure: true });
    setCookie(event, 'code_verifier', cookies.code_verifier, { path, sameSite: 'none', secure: true });
    setCookie(event, 'authorisation_server_id', cookies.authorisation_server_id, {
      path,
      sameSite: 'none',
      secure: true,
    });
    setCookie(event, 'next_page', cookies.next_page, { path, sameSite: 'none', secure: true });
  }

  function getCookies(event: H3Event): { [key: string]: string } {
    const cookies = parseCookies(event);
    return {
      state: cookies?.state,
      nonce: cookies?.nonce,
      code_verifier: cookies?.code_verifier,
      authorisation_server_id: cookies?.authorisation_server_id,
      next_page: cookies?.next_page,
    };
  }

  function clearCookies(event: H3Event): void {
    deleteCookie(event, 'state');
    deleteCookie(event, 'nonce');
    deleteCookie(event, 'code_verifier');
    deleteCookie(event, 'authorisation_server_id');
    deleteCookie(event, 'next_page');
  }

  async function initializeConnectID(nextPage: string = ''): Promise<void> {
    const options = {
      onProceed: async function (authorisationServerId: string) {
        loading.value = true;
        const essentialClaims = ['txn', 'given_name', 'middle_name', 'family_name'];
        const voluntaryClaims = ['over18'];
        const response = await fetch('/api/connect-id/select-bank', {
          method: 'POST',
          body: JSON.stringify({
            authorisationServerId,
            essentialClaims,
            next_page: nextPage,
            voluntaryClaims,
          }),
          headers: {
            'Content-Type': 'application/json',
          },
        });
        if (!response.ok) {
          const { error } = await response.json();
          alert(JSON.stringify(error));
          loading.value = false;
          return;
        }

        const { authUrl } = await response.json();

        window.location.href = authUrl;
      },
      buttonLabel: 'Verify',
      rpLogoUrl:
        'https://data.directory.sandbox.connectid.com.au/logos/c92eaf76-9519-4496-aff6-7d7186ab5026/softwarestatements/3bb84f45-0c9b-45d2-8841-2fc5c0dc098c.png',
      certificationStatus: 'Active',
      claims: ['given_name', 'middle_name', 'family_name', 'over18'], // 'email', 'phone_number', 'address', 'birthdate'
      participantsUri: useRuntimeConfig().public?.connectID?.participantsUri,
      enableManualVerification: false,
      theme: 'dark',
      rpDataSharingDescription:
        'Your details will be shared for the purpose of verifying your identity with SupportApp.',
    };

    window.cidInitIdpSelector(options);
  }

  return {
    initializeConnectID,
    loading,
    setCookies,
    getCookies,
    clearCookies,
  };
}
