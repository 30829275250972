<script setup lang="ts">
import connectIDImg from '@/assets/img/icon/ic-connectid-Verified-badge.png';
import useConnectID from '@/composables/useConnectID';
import { AppButton, AppConfirmDialog, AppFormCard, AppFormHeader, AppTypography } from '@/core/components';
import { OnboardingIdSelector } from '@/modules/auth/components/onboarding';
import { useAuthStore } from '@/modules/auth/store/auth';
import useSnackbarStore from '@/store/snackbar';
import { storeToRefs } from 'pinia';

const props = withDefaults(
  defineProps<{
    title?: string;
    description?: string;

    isOnboarding?: boolean;
  }>(),
  {
    title: 'Identity Documents',
    description: '',

    isOnboarding: true,
  }
);

const emits = defineEmits<{
  (e: 'success'): void;
  (e: 'next', next: string): void;
  (e: 'skip', next: string): void;
}>();

useHead({
  script: [
    {
      src: 'https://cdn.connectid.com.au/idp-selector/8-latest/cid-idp-selector.js',
      tagPosition: 'bodyClose',
      defer: true,
    },
  ],
});

const route = useRoute();
const router = useRouter();
const snackbar = useSnackbarStore();
const { user, isClient, isIdDocumentValid } = storeToRefs(useAuthStore());
const {
  fetching,
  frankieOneStatus,
  isVerificationPassed,
  isVerificationFailed,
  isVerificationRefer,
  isVerificationUnchecked,
  isVerificationNone,
  getFrankieOneStatus,
} = useFrankieOne();

const continuePage = ref('');
const connectIdClaims = ref(null);
const { initializeConnectID, loading: loadingConnectID } = useConnectID();

const statusDialog = ref(false);
watch(loadingConnectID, (value) => {
  statusDialog.value = value;
});

const isRetrievingTokens = ref(false);
watch(isRetrievingTokens, (value) => {
  statusDialog.value = value;
});

const errorDialog = ref(false);
const errorMessage = ref('Please try again.');
// watch(isVerificationFailed, (value) => {
//   errorDialog.value = value;
// });

onMounted(async () => {
  if (route.query.cb && !route.query?.access_denied) {
    isRetrievingTokens.value = true;
    const response = await fetch('/api/connect-id/retrieve-tokens', {
      method: 'POST',
      body: JSON.stringify({
        code: route.query.code,
        iss: route.query.iss,
        state: route.query.state,
        user_id: user.value.id,
      }),
      headers: {
        'Content-Type': 'application/json',
      },
    });
    isRetrievingTokens.value = false;

    if (!response.ok) {
      const { error } = await response.json();
      errorDialog.value = true;
    } else {
      const { getSession } = useAuth();
      await getSession();

      const { claims, token, nextPage } = await response.json();
      connectIdClaims.value = claims;
      snackbar.showSnackbar({
        text: 'User indentify pulled from ConnectID',
        state: true,
      });

      continuePage.value = nextPage;

      emits('success');
    }
  }

  nextTick(async () => {
    if (!isIdDocumentValid.value) {
      let nextPage = isClient.value ? 'profile-profile-information' : 'profile-profile-information-required-documents';
      // check if router has onboarding in path
      if (route.path.includes('onboarding')) {
        nextPage = isClient.value ? 'support-requirements' : 'required-documents';
      }

      await initializeConnectID(nextPage);
    }
  });
});

const statusMessage = computed((): string => {
  if (isRetrievingTokens.value) {
    return 'Retrieving data from ConnectID...';
  } else if (loadingConnectID.value) {
    return 'Redirecting you to ConnectID...';
  } else {
    return 'Redirecting you to FrankieOne...';
  }
});

const submitAttribute = computed(
  (): {
    label: string;
    variant: string;
  } => {
    if (isVerificationPassed.value || isIdDocumentValid.value) {
      return {
        label: 'Verified',
        variant: 'text',
      };
    } else if (isVerificationFailed.value || isVerificationRefer.value) {
      return {
        label: 'Resubmit',
        variant: 'tonal',
      };
    } else {
      return {
        label: 'Verify with Identity Documents',
        variant: 'tonal',
      };
    }
  }
);

const skipAttribute = computed(
  (): {
    label: string;
    variant: string;
  } => {
    if (isVerificationFailed.value || isVerificationRefer.value) {
      return {
        label: 'Skip',
        variant: 'text',
      };
    } else if (isVerificationPassed.value || isIdDocumentValid.value) {
      return {
        label: 'Continue',
        variant: 'tonal',
      };
    } else {
      return {
        label: 'Complete later',
        variant: 'text',
      };
    }
  }
);

const title = computed((): string => {
  return `ID ${isClient.value ? 'Verification' : 'Documents'}`;
});

const subtitle = computed((): string => {
  if (connectIdClaims.value) {
    return 'Review your Details';
  } else if (isVerificationPassed.value || isIdDocumentValid.value) {
    return 'Verification success.';
  } else if (isVerificationFailed.value) {
    return 'Verification failed. Try again.';
  } else if (isVerificationRefer.value) {
    return 'Your identity check needs admin attention.';
  } else {
    return 'Verify your identity with our secure partners.';
  }
});

const hasSubmitted = computed((): boolean => {
  return !isVerificationNone.value || !isVerificationUnchecked.value;
});

const pending = ref(false);
async function initialize() {
  pending.value = true;
  if (!isIdDocumentValid.value && frankieOneStatus.value === null) {
    await getFrankieOneStatus();
  }
  pending.value = false;
}

const submit = async () => {
  router.push({
    name: 'frankieone-verification',
    query: {
      from: route.name === 'onboarding' ? 'onboarding' : 'profile',
    },
  });
};

const { error: serverError, execute: execSubmit, pending: buzy } = useSubmit();
const submitConnectID = async () => {
  if (connectIdClaims.value) {
    await execSubmit('/connect-id/document-status', {
      method: 'POST',
    });

    if (serverError.value) {
      snackbar.showSnackbar({
        text: 'Failed to submit ConnectID verification',
        state: false,
      });
    } else {
      snackbar.showSnackbar({
        text: 'Your identity has been confirmed',
        state: true,
      });

      await next();
    }
  }
};

const next = async (): Promise<void> => {
  let nextPage = { name: continuePage.value, params: {} } as Record<string, any>;
  if (route.path.includes('onboarding')) {
    nextPage = { name: 'onboarding', query: { form: isClient.value ? 'support-requirements' : 'required-documents' } };
  }

  await navigateTo(nextPage);
};

onMounted(() => {
  nextTick(() => {
    initialize();
  });
});

const connectIdOnValidate = () => {
  document.getElementById('cid-idp-selector-popup').style.cssText = 'display: block; z-index: 9999';
};

const showTooltip = ref(false);
</script>

<template>
  <div class="d-flex flex-column">
    <AppFormHeader
      v-if="!isOnboarding"
      :title="title"
      :description="description"
      :loading="pending"
      :show-button="false"
      class="mb-5"
    />
    <div v-if="user.fo_verification || isOnboarding" class="id-document">
      <AppFormCard :title="title" :subtitle="subtitle" no-gutter>
        <AppTypography v-if="!connectIdClaims" element="p" class="d-block mt-4" type="body-1">
          We have partnered with FrankieOne and ConnectID to bring you a secure way to verify your identity. You may
          choose either option to verify your identity. You only need to do this once.
        </AppTypography>
        <div v-if="connectIdClaims">
          <v-divider class="mt-4" />
          <v-img :src="connectIDImg" class="verified-connectid-badge mt-4" max-height="35" />
          <AppTypography element="p" class="d-block mt-4" type="body-1">
            <strong>First Name:</strong> {{ connectIdClaims?.given_name }}
          </AppTypography>
          <AppTypography element="p" class="d-block mt-4" type="body-1">
            <strong>Middle Name:</strong> {{ connectIdClaims?.middle_name }}
          </AppTypography>
          <AppTypography element="p" class="d-block mt-4" type="body-1">
            <strong>Last Name:</strong> {{ connectIdClaims?.family_name }}
          </AppTypography>
          <AppTypography element="p" class="d-block mt-4" type="body-1">
            <strong>Over 18+:</strong> <v-icon>mdi-check</v-icon>
          </AppTypography>
        </div>
      </AppFormCard>

      <AppButton
        v-if="connectIdClaims"
        block
        color="primary"
        class="id-document__actions__skip mt-4"
        type="button"
        variant="tonal"
        @click="next"
      >
        Submit
      </AppButton>

      <div v-if="!connectIdClaims" class="document-connect-id">
        <AppTypography class="mt-4 text-center px-12" type="body-2">
          Verify your identity using only the data that is necessary with ConnectID. ConnectID does not see or store
          your personal data.
        </AppTypography>

        <div class="d-flex flex-column">
          <div class="d-flex justify-center mt-4">
            <div id="cid-idp-selector" @click="connectIdOnValidate" />
          </div>

          <div class="d-flex flex-column">
            <div class="mt-4 text-center d-flex align-center justify-center">
              <v-tooltip
                v-model="showTooltip"
                location="top"
                max-width="400px"
                close-delay="1500"
                theme="light"
                content-class="custom-tooltip"
              >
                <template v-slot:activator="{ props }">
                  <v-icon v-bind="props">mdi-information-outline</v-icon>
                </template>
                <p>
                  ConnectID is an Australian-owned digital identity solution which allows you to securely prove who you
                  are. ConnectID does not see or store your personal data. Visit
                  <NuxtLink target="_blank" href="https://connectid.com.au/" class="text-primary">
                    connectid.com.au
                  </NuxtLink>
                  to find out more.
                </p>
              </v-tooltip>
              <AppTypography type="body-bold" class="pl-2"> What's ConnectID? </AppTypography>
            </div>
            <AppTypography v-show="$device.isAndroid" type="footnote-2" class="px-12 pt-2 text-center">
              ConnectID is an Australian-owned digital identity solution which allows you to securely prove who you are.
              ConnectID does not see or store your personal data. Visit
              <NuxtLink target="_blank" href="https://connectid.com.au/" class="text-primary">
                connectid.com.au
              </NuxtLink>
              to find out more.
            </AppTypography>
          </div>
        </div>
      </div>

      <AppTypography v-if="!connectIdClaims" type="body-1" class="text-center pt-6 pb-2">Or</AppTypography>

      <div v-if="isVerificationFailed || isVerificationRefer" class="id-document__footnote mt-5">
        <AppTypography element="span" type="headline-2" class="d-block text-center text-decoration-none">
          Contact
          <a href="mailto:info@supportapp.com.au" class="font-weight-bold text-decoration-none text-primary">
            info@supportapp.com.au
          </a>
          for assistance if failure persists
        </AppTypography>
      </div>

      <div
        v-if="!connectIdClaims"
        class="id-document__actions d-flex flex-column"
        :class="{
          'id-document__actions--submitted': hasSubmitted,
        }"
      >
        <AppButton
          v-if="isOnboarding"
          block
          color="primary"
          class="id-document__actions__skip mt-4"
          type="button"
          :variant="skipAttribute.variant"
          @click="next()"
        >
          {{ skipAttribute.label }}
        </AppButton>
        <AppButton
          v-if="!fetching && !!frankieOneStatus && !isIdDocumentValid"
          block
          color="primary"
          class="id-document__actions__save mt-2"
          type="button"
          :variant="submitAttribute.variant"
          :loading="pending"
          :readonly="isVerificationPassed"
          @click="submit()"
        >
          {{ submitAttribute.label }}
        </AppButton>
      </div>
    </div>
    <OnboardingIdSelector v-else :title="title" :is-onboarding="isOnboarding" />
  </div>

  <v-dialog v-model="statusDialog" width="400px" persistent class="custom-dialog">
    <v-card class="custom-card">
      <template #append>
        <v-btn size="small" icon @click="statusDialog = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
      <v-card-text class="text-center">
        <div class="d-flex flex-column align-center justify-center">
          <v-progress-circular indeterminate color="primary" size="100" class="mb-4" />
          <span class="ml-2">{{ statusMessage }}</span>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>

  <AppConfirmDialog
    v-model="errorDialog"
    title="Verification Failed"
    :description="errorMessage"
    confirm-only
    confirm-text="Close"
    @confirm="errorDialog = false"
  />
</template>

<style lang="scss" scoped>
.id-document {
  width: 100%;
  max-width: var(--form-max-width);

  &__actions {
    // &--submitted {
    //   > *:first-child {
    //     order: 2;
    //   }
    // }

    .v-btn {
      text-transform: none !important;
    }
  }

  &__footnote {
    width: 100%;
    max-width: 330px;
    margin: 0 auto;
  }
}

.inner-card {
  border: 1px solid #e0e0e0;
}

:deep(.app-form-header) {
  align-items: start !important;
}

:deep(.custom-tooltip) {
  background-color: white;
  color: #000;
  border-color: #e0e0e0;
}

.document-connect-id {
  width: 100%;
  max-width: var(--form-max-width);
  margin: 0 auto;

  :deep(#cid-idp-selector) {
    width: 100% !important;
  }

  :deep(.cid-idp-selector-button) {
    border-radius: 24px !important;
    min-height: 56px !important;
    width: 100% !important;
  }
}

.custom-dialog {
  :deep(.v-card) {
    .v-card-item {
      padding: 16px 8px;
    }
  }
}
</style>
